import { createContext, useContext } from "react"

export type SendEventCallback = (eventId: typeof events[keyof typeof events], object: any) => void

const sendEventFallback: SendEventCallback = (eventId, object) => {
    // eslint-disable-next-line no-console
    console.log("sendEvent", eventId, object)
}

export const AnalyticsContext = createContext<SendEventCallback>(sendEventFallback)

export const useAnalytics = () => {
    const sendEvent = useContext(AnalyticsContext)

    return {
        sendEvent,
    }
}

export const events = {
    SignedUp: "SignedUp",
    OnboardingCompleted: "OnboardingCompleted",
    VideoPlayed: "VideoPlayed",
    DemoAssessmentVisited: "DemoAssessmentVisited",
    DemoSolutionVisited: "DemoSolutionVisited",
    PricingVisited: "PricingVisited",
    PlanClicked: "PlanClicked",
    WizardVisited: "WizardVisited",
    WizardNewSubmitted: "WizardNewSubmitted",
    WizardSkillsSubmitted: "WizardSkillsSubmitted",
    WizardOpenQuestionsSubmitted: "WizardOpenQuestionsSubmitted",
    AssessmentCreated: "AssessmentCreated",
    AssessmentVisited: "AssessmentVisited",
    SolutionVisited: "SolutionVisited",
} as const
