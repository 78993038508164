import "antd/lib/notification/style/index.css"

import { borderRadiusMd, createGlobalStyle } from "../theme"

export { notification } from "antd"

export const NotificationStyles = createGlobalStyle`

    .ant-notification-notice {
        border-radius: ${borderRadiusMd};
    }
`
