import _styled, {
    createGlobalStyle as _createGlobalStyle,
    css as _css,
    CSSObject,
    GlobalStyleComponent,
    Interpolation,
    InterpolationFunction,
    ThemedCssFunction,
    ThemedStyledInterface,
    ThemedStyledProps,
    ThemeProvider as _ThemeProvider,
    useTheme as _useTheme,
} from "styled-components"

import { Theme } from "./theme"

export const styled = _styled as any as ThemedStyledInterface<Theme>

export const createGlobalStyle = _createGlobalStyle as any as (
    first: TemplateStringsArray | CSSObject | InterpolationFunction<ThemedStyledProps<void, Theme>>,
    ...interpolations: Array<Interpolation<ThemedStyledProps<void, Theme>>>
) => GlobalStyleComponent<void, Theme>

export const useTheme = _useTheme as any as () => Theme

export const css = _css as ThemedCssFunction<Theme>

export const ThemeProvider = _ThemeProvider as any as React.ComponentType<{
    theme: Theme
    children: React.ReactNode
}>
