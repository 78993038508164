export * from "./auth"
export * from "./number"
export * from "./dates"
export * from "./language"
export * from "./selectors"
export * from "./scalars"
export * from "./unpackQueryArray"

export const getHostname = () =>
    window.location.hostname === "localhost"
        ? `${window.location.hostname}:${window.location.port}`
        : `https://${window.location.hostname}`

export const getPublicTestUrl = (testId: string) => {
    return `${getHostname()}/test?id=${testId}`
}

export const typeformFormUrl = (typeformTestId: string) => {
    return `https://admin.typeform.com/form/${typeformTestId}/create`
}

export const getSetPasswordHrUrl = (token: string) => {
    const setPasswordUrl = new URL(`${getHostname()}/set_password`)
    setPasswordUrl.searchParams.append("resetToken", token)

    return setPasswordUrl
}
