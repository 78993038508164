import React from "react"
import { Flex } from "@/components/common"
import { Loader } from "@/components/ui"
import { useAuth } from "@/containers/authData"
import { ImpersonatorLayout } from "@/layouts/ImpersonatorLayout"
import { paths } from "@/paths"
import { LegacyThemeProvider } from "@/styles/ThemeProvider"
import { PageProps } from "@/utils/next"
import { Spin } from "antd"
import { useRouter } from "next/router"

import { UIProvider } from "../ui/Provider"

export type ProtectedRoutingProps = {
    appUsesNewTheme?: boolean
    children: React.ReactNode
} & Pick<PageProps, "restrictredUserTypes">

export const ProtectedRouting = ({
    children,
    restrictredUserTypes = [],
    appUsesNewTheme = false,
}: ProtectedRoutingProps) => {
    const router = useRouter()
    const { userType, userData, setUrlBeforeLoginRedirect, isImpersonating, shareMode } = useAuth()

    const pageIsRestrictedForSomeUserTypes = restrictredUserTypes.length > 0

    const isRestricted = restrictredUserTypes.some(restrictedUserType => restrictedUserType === userType) && !shareMode

    const onboardingNotCompleted = userData && !userData.onboardingCompleted
    React.useEffect(() => {
        if (onboardingNotCompleted && router.pathname !== paths.onboarding.pathname) {
            router.push(paths.onboarding.go())
        }
    }, [router, onboardingNotCompleted])

    React.useEffect(() => {
        if (isRestricted) {
            router.replace(userType === "hr" ? paths.notFound.go() : paths.login.go())
            setUrlBeforeLoginRedirect(router.asPath)
        }
    }, [isRestricted, router, setUrlBeforeLoginRedirect, userType])

    const [isSsr, setIsSsr] = React.useState(true)
    React.useEffect(() => {
        setIsSsr(false)
    }, [])

    const loader = appUsesNewTheme ? (
        <Loader fullH />
    ) : (
        <Flex align="center" justify="center" minHeight="90vh" fullW>
            <Spin />
        </Flex>
    )

    if (!router.isReady) return loader
    if (isSsr) return loader
    if (pageIsRestrictedForSomeUserTypes && userType === "unknown") return loader
    if (isRestricted) return loader

    if (isImpersonating) {
        return (
            <LegacyThemeProvider>
                <ImpersonatorLayout>
                    {appUsesNewTheme ? <UIProvider>{children}</UIProvider> : children}
                </ImpersonatorLayout>
            </LegacyThemeProvider>
        )
    }

    return <>{children}</>
}
