import "antd/dist/antd.css"
import "@/styles/antd.less"
import "../public/fonts/style.css"

import React from "react"
import { I18nextProvider } from "react-i18next"
import { ProtectedRouting } from "@/components/common/ProtectedRouting"
import { FeatureFlagsProvider } from "@/components/ui/FeatureFlags"
import { GoogleTagManger, UserDotCom } from "@/components/ui/Scripts"
import { AuthProvider } from "@/containers/authData"
import { ApolloProvider } from "@/graphql/apollo/ApolloProvider"
import { UserDotComAnalyticsProvider } from "@/hooks/UserDotComAnalyticsProvider"
import i18n from "@/i18n"
import commonEN from "@/locales/en/common.json"
import metaTitlesEN from "@/locales/en/metaTitles.json"
import navEN from "@/locales/en/nav.json"
import commonPL from "@/locales/pl/common.json"
import metaTitlesPL from "@/locales/pl/metaTitles.json"
import navPL from "@/locales/pl/nav.json"
import { GlobalStyles } from "@/styles/globalStyles"
import { theme } from "@/styles/theme"
import { PageProps } from "@/utils/next"
import { ThemeProvider } from "styled-components"

i18n.addResources("pl", "common", commonPL)
i18n.addResources("en", "common", commonEN)

i18n.addResources("pl", "nav", navPL)
i18n.addResources("en", "nav", navEN)

i18n.addResources("pl", "metaTitles", metaTitlesPL)
i18n.addResources("en", "metaTitles", metaTitlesEN)

export default function App(props: PageProps) {
    const { Component } = props
    const component = Component.getLayout ? Component.getLayout(props) : <LegacyAppLayout {...props} />

    return (
        <>
            <GoogleTagManger />
            <FeatureFlagsProvider>
                <ApolloProvider>
                    <AuthProvider>
                        <UserDotCom />
                        <UserDotComAnalyticsProvider>
                            <I18nextProvider i18n={i18n}>{component}</I18nextProvider>
                        </UserDotComAnalyticsProvider>
                    </AuthProvider>
                </ApolloProvider>
            </FeatureFlagsProvider>
        </>
    )
}

function LegacyAppLayout({ Component, pageProps }: PageProps) {
    return (
        <ThemeProvider theme={theme}>
            <ProtectedRouting {...pageProps}>
                <GlobalStyles />
                <Component {...pageProps} />
                {/* Place for rendering modals from `src/components/ui/Modal`  */}
                <div id="modal-portal-target" />
            </ProtectedRouting>
        </ThemeProvider>
    )
}
