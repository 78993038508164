import { useAuth } from "@/containers/authData"
import env from "@/env"

import { AnalyticsContext, events } from "./useAnalytics"

export const UserDotComAnalyticsProvider: React.FC = ({ children }) => {
    const { isImpersonating } = useAuth()
    const userDotComApiKey = env.userDotComApiKey

    const sendEvent = (eventId: typeof events[keyof typeof events], object: any) => {
        if (window.userengage) {
            window.userengage(`event.${eventId}`, object)
        }
    }

    if (isImpersonating || !userDotComApiKey) {
        return <>{children}</>
    }

    return <AnalyticsContext.Provider value={sendEvent}>{children}</AnalyticsContext.Provider>
}
