import envJson from "./env.json"

const createEnv = () => {
    let env = envJson["production"]
    const hostname = typeof window !== "undefined" && window?.location?.hostname

    if (hostname === "localhost" || /\.test$/.test(hostname)) {
        env = envJson["local"]
    }

    if (hostname === "app-dev.heroify.co") {
        env = envJson["dev"]
    }

    if (hostname === "app-staging.heroify.co") {
        env = envJson["staging"]
    }

    if (hostname === "app.heroify.co") {
        env = envJson["production"]
    }

    env = {
        apiUrl: process.env.NEXT_PUBLIC_API_URL ?? env.apiUrl,
        newApiUrl: process.env.NEXT_PUBLIC_NEW_API_URL ?? env.newApiUrl,
        gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? env.gtmId,
        deploymentEnv: process.env.NEXT_PUBLIC_DEPLOYMENT_ENV ?? env.deploymentEnv,
        sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN ?? env.sentryDsn,
        sentryAuthToken: process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN ?? env.sentryAuthToken,
        slackCustomerSupportWebhookUrl:
            process.env.NEXT_PUBLIC_SLACK_CUSTOMER_SUPPORT_WEBHOOK_URL ?? env.slackCustomerSupportWebhookUrl,
        newRelicApplicationId: process.env.NEXT_PUBLIC_NEW_RELIC_APPLICATION_ID ?? env.newRelicApplicationId,
        newRelicAgentId: process.env.NEXT_PUBLIC_NEW_RELIC_AGENT_ID ?? env.newRelicAgentId,
        userDotComApiKey: process.env.NEXT_PUBLIC_USER_DOT_COM_API_KEY ?? env.userDotComApiKey,
        userDotComDomain: process.env.NEXT_PUBLIC_USER_DOT_COM_DOMAIN ?? env.userDotComDomain,
    }

    return env
}

export default createEnv()
