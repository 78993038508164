/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		supportedSkills:{
			position:"PositionInput"
		},
		suggestedSkills:{
			position:"PositionInput"
		},
		solutionResponses:{
			solutionResponsesInput:"SolutionResponsesInput"
		},
		solutionResponsesWithToken:{
			solutionResponsesInput:"SolutionResponsesInput"
		}
	},
	UUID: `scalar.UUID` as const,
	UserLocale: "enum" as const,
	DateTime: `scalar.DateTime` as const,
	AccountLocale: "enum" as const,
	AccountBillingModel: "enum" as const,
	SubscriptionState: "enum" as const,
	Locale: "enum" as const,
	Feature: "enum" as const,
	Icon: "enum" as const,
	SkillType: "enum" as const,
	PositionInput:{
		testLevel:"TestLevel",
		testType:"TestType",
		locale:"Locale"
	},
	TestLevel: "enum" as const,
	TestType: "enum" as const,
	CandidateStatus: "enum" as const,
	Decimal: `scalar.Decimal` as const,
	PersonalityFit: "enum" as const,
	AreaScoreRange: "enum" as const,
	NotificationFrequency: "enum" as const,
	ExternalToolKind: "enum" as const,
	TestState: "enum" as const,
	Seniority: "enum" as const,
	QuestionTypes: "enum" as const,
	ResponseLabel: "enum" as const,
	SolutionResponsesInput:{
		solutionId:"UUID"
	},
	ClientQuery:{
		solution:{
			uuid:"UUID"
		},
		test:{
			uuid:"UUID"
		},
		solutionWithToken:{
			uuid:"UUID"
		},
		suggestedOpenQuestionsConnection:{
			skillsUuids:"UUID",
			locale:"Locale"
		},
		suggestedOpenAiQuestionsConnection:{
			locale:"Locale"
		},
		solutionConnection:{
			uuid:"UUID"
		},
		skillsBenchmark:{
			skillsUuids:"UUID",
			testLevel:"TestLevel",
			testType:"TestType"
		},
		assessmentPreview:{
			uuid:"UUID"
		},
		solutionResponses:{
			solutionUuid:"UUID"
		},
		solutionResponsesWithToken:{
			solutionUuid:"UUID"
		},
		assessment:{
			uuid:"UUID"
		},
		assessmentConnection:{

		},
		personalityTestResult:{
			solutionId:"UUID"
		}
	},
	Role: "enum" as const,
	ScoreRange: "enum" as const,
	CandidateQuery:{
		solutionAttemptCandidate:{

		},
		solutionResults:{
			id:"UUID"
		},
		assessment:{
			id:"UUID"
		},
		solution:{
			id:"UUID"
		},
		candidateTestPreview:{
			id:"UUID"
		},
		candidateSolutionPreview:{

		}
	},
	SolutionState: "enum" as const,
	Mutation:{
		auth:{

		},
		heroTracking:{
			input:"TrackInput"
		}
	},
	TrackInput:{

	},
	CandidateMutation:{
		talentPoolOptIn:{
			solutionId:"UUID"
		},
		addCommentForQuestion:{
			questionId:"UUID",
			solutionId:"UUID"
		},
		submitSolution:{
			solutionId:"UUID"
		},
		reportAnomaly:{
			responseId:"UUID",
			type:"AnomalyType"
		},
		startSolution:{

		},
		submitLikertResponse:{
			answer:"LikertAnswer",
			id:"UUID"
		},
		submitTest:{
			input:"SubmitTestInput"
		},
		registerCandidate:{
			assessmentId:"UUID"
		},
		issue2faToken:{
			testId:"UUID"
		},
		verify2faToken:{
			testId:"UUID"
		},
		registerVerifiedCandidate:{

		},
		startTest:{
			input:"StartTestInput"
		},
		seeQuestion:{
			input:"SeeQuestionInput"
		},
		submitResponse:{
			input:"SubmitResponseInput"
		},
		submitSituationalResponse:{
			input:"SubmitSituationalResponseInput"
		},
		skipQuestion:{
			input:"SkipQuestionInput"
		},
		giveFeedback:{
			feedbackScore:"Decimal"
		}
	},
	AnomalyType: "enum" as const,
	LikertAnswer: "enum" as const,
	SubmitTestInput:{

	},
	StartTestInput:{

	},
	SeeQuestionInput:{
		questionId:"UUID"
	},
	SubmitResponseInput:{
		questionId:"UUID",
		answersGiven:"AnswerGivenInput"
	},
	AnswerGivenInput:{
		answerId:"UUID"
	},
	SubmitSituationalResponseInput:{
		questionId:"UUID",
		answersGiven:"SituationalAnswerGivenInput"
	},
	SituationalAnswerGivenInput:{
		answerId:"UUID"
	},
	SkipQuestionInput:{
		questionId:"UUID"
	},
	ClientMutation:{
		startCheckoutSession:{

		},
		createAssessment:{
			existingOpenQuestions:"UUID",
			locale:"Locale",
			seniority:"Seniority",
			skillsUuids:"UUID",
			testType:"TestType"
		},
		signUp:{
			locale:"Locale"
		},
		submitOnboardingSurvey:{
			survey:"JSONString"
		},
		submitOnboardingAccountDetails:{
			position:"Role"
		},
		sendEmail:{
			solutionIds:"UUID"
		},
		changeNotificationFrequency:{
			assessmentId:"UUID",
			notificationFrequency:"NotificationFrequency"
		},
		solutionToken:{
			solutionUuid:"UUID"
		},
		changeAtsAssociationSettings:{
			assessmentUuid:"UUID"
		},
		deleteAtsAssociation:{
			assessmentUuid:"UUID"
		},
		earlyAccessSignup:{
			locale:"Locale"
		},
		setPassword:{

		},
		sendResetPassword:{
			resetPassword:"SendResetPasswordInput"
		},
		archiveTest:{
			testId:"UUID"
		},
		deleteTest:{
			testId:"UUID"
		},
		updateCandidatesStatus:{
			input:"UpdateCandidatesStatusInput"
		},
		updateBranding:{
			input:"BrandingInput"
		},
		seeSolution:{
			seeSolution:"SeeSolution"
		}
	},
	JSONString: `scalar.JSONString` as const,
	SendResetPasswordInput:{

	},
	UpdateCandidatesStatusInput:{
		candidateIds:"UUID",
		status:"CandidateStatusInput"
	},
	CandidateStatusInput: "enum" as const,
	BrandingInput:{
		logo:"Upload",
		image:"Upload"
	},
	Upload: `scalar.Upload` as const,
	SeeSolution:{
		solutionId:"UUID"
	}
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		_debug:"DjangoDebug",
		me:"User",
		supportedSkills:"SkillConnection",
		suggestedSkills:"SkillConnection",
		solutionResponses:"SolutionResponses",
		solutionResponsesWithToken:"SolutionResponses",
		client:"ClientQuery",
		candidate:"CandidateQuery"
	},
	DjangoDebug:{
		sql:"DjangoDebugSQL"
	},
	DjangoDebugSQL:{
		vendor:"String",
		alias:"String",
		sql:"String",
		duration:"Float",
		rawSql:"String",
		params:"String",
		startTime:"Float",
		stopTime:"Float",
		isSlow:"Boolean",
		isSelect:"Boolean",
		transId:"String",
		transStatus:"String",
		isoLevel:"String",
		encoding:"String"
	},
	User:{
		uuid:"UUID",
		email:"String",
		firstName:"String",
		lastName:"String",
		locale:"UserLocale",
		role:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		account:"Account",
		lastSeenAt:"DateTime",
		phone:"String",
		active:"Boolean",
		admin:"Boolean",
		accountLocale:"Locale",
		onboardingCompleted:"Boolean",
		expiresOn:"DateTime",
		emailConfirmed:"Boolean",
		features:"Feature"
	},
	UUID: `scalar.UUID` as const,
	DateTime: `scalar.DateTime` as const,
	Account:{
		id:"ID",
		name:"String",
		expiresOn:"DateTime",
		locale:"AccountLocale",
		billingModel:"AccountBillingModel",
		tokensRemaining:"Int",
		slots:"Int",
		brandingEnabled:"Boolean",
		hasSubscription:"Boolean",
		verify:"Boolean",
		subscription:"Subscription",
		slotsInUse:"Int"
	},
	Subscription:{
		status:"SubscriptionState"
	},
	SkillConnection:{
		pageInfo:"PageInfo",
		edges:"SkillEdge"
	},
	PageInfo:{
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean",
		startCursor:"String",
		endCursor:"String"
	},
	SkillEdge:{
		node:"Competency",
		cursor:"String"
	},
	Competency:{
		uuid:"UUID",
		name:"String",
		domain:"String",
		icon:"Icon",
		description:"String",
		skillType:"SkillType",
		testDuration:"Int"
	},
	SolutionResponses:{
		"...on SolutionResponsesSuccess":"SolutionResponsesSuccess",
		"...on SolutionResponsesError":"SolutionResponsesError"
	},
	SolutionResponsesSuccess:{
		tests:"TestSolution"
	},
	TestSolution:{
		id:"ID",
		solution:"Solution",
		startedAt:"DateTime",
		submittedAt:"DateTime",
		score:"Decimal",
		responses:"SolutionTestResponse",
		skillName:"String"
	},
	Solution:{
		candidate:"SolutionCandidate",
		uuid:"UUID",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		seenAt:"DateTime",
		submittedAt:"DateTime",
		testRoleName:"String",
		starred:"Boolean",
		solutionState:"String",
		score:"Decimal",
		timeSpent:"Int",
		personalityFit:"PersonalityFit",
		skillSummaries:"SkillSummary",
		summary:"SummaryEntry",
		notificationSentAt:"DateTime",
		notificationsFrequency:"NotificationFrequency",
		assessment:"Assessment",
		openQuestionsResponses:"Response",
		situationalSummaries:"SituationalSummary",
		hideMedians:"Boolean"
	},
	SolutionCandidate:{
		firstName:"String",
		lastName:"String",
		email:"String",
		uuid:"UUID",
		phoneNumber:"String",
		status:"CandidateStatus"
	},
	Decimal: `scalar.Decimal` as const,
	SkillSummary:{
		skillUuid:"UUID",
		name:"String",
		score:"Decimal",
		availableTime:"Int",
		timeSpent:"Int",
		areas:"Area",
		activities:"JobActivity",
		comment:"String",
		detailsIncluded:"Boolean",
		suggestedQuestions:"String",
		questionsAnswered:"Int",
		questionsTotal:"Int"
	},
	Area:{
		name:"String",
		score:"Decimal",
		scoreRange:"AreaScoreRange",
		comment:"String",
		explanation:"String"
	},
	JobActivity:{
		name:"String"
	},
	SummaryEntry:{
		competency:"Competency",
		score:"Decimal",
		availableTime:"Int",
		timeSpent:"Int",
		totalQuestionsCount:"Int",
		answeredQuestionsCount:"Int",
		comment:"String"
	},
	Assessment:{
		uuid:"UUID",
		title:"String",
		roleName:"String",
		availableTime:"Int",
		numberOfQuestions:"Int",
		numberOfOpenQuestions:"Int",
		organizationName:"String",
		requiresExternalTool:"Boolean",
		requiredExternalTools:"ExternalToolKind",
		description:"String",
		competencies:"Competency",
		locale:"Locale",
		talentPool:"Boolean",
		testType:"TestType",
		testLevel:"TestLevel",
		state:"TestState",
		notificationFrequency:"NotificationFrequency",
		createdAt:"DateTime",
		seniority:"Seniority",
		solutionsDistribution:"Bin",
		averageSkillSummary:"SummarySkillEntry",
		atsAssociation:"AtsAssociation",
		completedBy:"Int",
		mostRecentActivityAt:"DateTime",
		mostRecentActivitySecondsAgo:"Int"
	},
	Bin:{
		label:"String",
		value:"Int",
		selected:"Boolean"
	},
	SummarySkillEntry:{
		skill:"Skill",
		score:"Float"
	},
	Skill:{
		uuid:"UUID",
		name:"String",
		domain:"String",
		icon:"Icon",
		description:"String",
		type:"SkillType"
	},
	AtsAssociation:{
		integration:"AtsIntegration",
		assessment:"Assessment",
		jobId:"String",
		stageId:"String",
		emailText:"String",
		emailSubject:"String",
		lastChangedAt:"DateTime"
	},
	AtsIntegration:{
		account:"Account",
		remoteId:"String"
	},
	Response:{
		uuid:"UUID",
		question:"Question",
		score:"Decimal",
		scored:"Boolean",
		outdated:"Boolean",
		answersGiven:"AnswerGiven"
	},
	Question:{
		uuid:"UUID",
		competencyName:"String",
		skill:"Skill",
		custom:"Boolean",
		questionType:"QuestionTypes",
		answers:"Answer",
		content:"String",
		appendix:"String",
		availableTime:"Int",
		questionStatus:"QuestionTypes",
		requiresExternalTool:"String",
		aiGenerated:"Boolean"
	},
	Answer:{
		uuid:"UUID",
		correct:"Boolean",
		content:"String"
	},
	AnswerGiven:{
		uuid:"UUID",
		answer:"Answer",
		correct:"Boolean",
		userSelected:"Boolean",
		content:"String"
	},
	SituationalSummary:{
		skillUuid:"UUID",
		name:"String",
		dimensions:"DimensionSummary"
	},
	DimensionSummary:{
		skillUuid:"UUID",
		name:"String",
		score:"Decimal",
		distribution:"Bin",
		comment:"String",
		scoreLabel:"String",
		dimensionComment:"String"
	},
	SolutionTestResponse:{
		question:"SolutionTestQuestion",
		label:"ResponseLabel",
		answerGiven:"String"
	},
	SolutionTestQuestion:{
		answers:"SolutionTestAnswer",
		content:"String",
		externalTool:"ExternalTool",
		image:"String"
	},
	SolutionTestAnswer:{
		content:"String"
	},
	ExternalTool:{
		instruction:"String",
		url:"String",
		name:"String"
	},
	SolutionResponsesError:{
		error:"String"
	},
	ClientQuery:{
		solution:"SolutionUnion",
		test:"TestAssessment",
		branding:"AccountBranding",
		solutionWithToken:"SolutionUnion",
		productPlan:"ProductPlan",
		suggestedOpenQuestionsConnection:"QuestionConnection",
		suggestedOpenAiQuestionsConnection:"QuestionConnection",
		solutionConnection:"SolutionConnection",
		skillsBenchmark:"SkillsBenchmarkConnection",
		currentSubscription:"CurrentSubscription",
		assessmentPreview:"AssessmentPreview",
		solutionResponses:"SolutionResponses",
		solutionResponsesWithToken:"SolutionResponses",
		atsAssociationOptions:"AtsJob",
		assessment:"Assessment",
		assessmentConnection:"AssessmentConnection",
		personalityTestResult:"PersonalityTestResult"
	},
	SolutionUnion:{
		"...on Solution":"Solution",
		"...on SolutionError":"SolutionError"
	},
	SolutionError:{
		error:"String"
	},
	TestAssessment:{
		uuid:"UUID",
		title:"String",
		roleName:"String",
		availableTime:"Int",
		numberOfQuestions:"Int",
		numberOfOpenQuestions:"Int",
		description:"String",
		organizationName:"String",
		branding:"AccountBranding",
		requiresExternalTool:"Boolean",
		requiredExternalTools:"ExternalToolKind",
		locale:"Locale",
		talentPool:"Boolean",
		testType:"TestType",
		testLevel:"TestLevel",
		competencies:"Skill",
		createdAt:"DateTime",
		expiresAt:"DateTime",
		updatedAt:"DateTime",
		mostRecentActivityAt:"DateTime",
		mostRecentActivitySecondsAgo:"Int",
		disabledAt:"DateTime",
		typeformTestId:"String",
		seniority:"Seniority",
		testState:"TestState",
		completedBy:"Int",
		averageSolutionTime:"Int",
		averageSolutionScore:"Decimal",
		assignedTo:"BaseUser",
		solutionsDistribution:"Bin",
		experimental:"Boolean",
		questions:"Question",
		skills:"Skill",
		averageSummary:"TestAssessmentSummary",
		solutionUuids:"UUID"
	},
	AccountBranding:{
		uuid:"UUID",
		logo:"String",
		image:"String",
		primaryColor:"String",
		secondaryColor:"String",
		headline:"String",
		description:"String"
	},
	BaseUser:{
		uuid:"UUID",
		firstName:"String",
		lastName:"String",
		organizationName:"String",
		email:"String",
		isAdmin:"Boolean",
		locale:"Locale",
		accountId:"Int",
		role:"Role",
		logUrl:"String",
		accountLogUrl:"String"
	},
	TestAssessmentSummary:{
		competency:"SummaryCompetency",
		numberOfQuestions:"Int",
		score:"Decimal"
	},
	SummaryCompetency:{
		uuid:"UUID",
		name:"String"
	},
	ProductPlan:{
		maxNumberOfSkills:"Int",
		maxNumberOfOpenQuestions:"Int"
	},
	QuestionConnection:{
		pageInfo:"PageInfo",
		edges:"QuestionEdge"
	},
	QuestionEdge:{
		node:"Question",
		cursor:"String"
	},
	SolutionConnection:{
		pageInfo:"PageInfo",
		edges:"SolutionEdge"
	},
	SolutionEdge:{
		node:"Solution",
		cursor:"String"
	},
	SkillsBenchmarkConnection:{
		pageInfo:"PageInfo",
		edges:"SkillsBenchmarkEdge"
	},
	SkillsBenchmarkEdge:{
		node:"SkillsBenchmark",
		cursor:"String"
	},
	SkillsBenchmark:{
		skill:"Skill",
		testType:"TestType",
		testLevel:"TestLevel",
		median:"Float",
		mean:"Float",
		standardDeviation:"Float",
		count:"Int"
	},
	CurrentSubscription:{
		plan:"SubscriptionPlan",
		cancelAtPeriodEnd:"Boolean",
		currentPeriodStart:"DateTime",
		currentPeriodEnd:"DateTime",
		status:"SubscriptionState"
	},
	SubscriptionPlan:{
		name:"String"
	},
	AssessmentPreview:{
		result:"AssessmentPreviewResult"
	},
	AssessmentPreviewResult:{
		"...on Assessment":"Assessment",
		"...on AssessmentPreviewError":"AssessmentPreviewError"
	},
	AssessmentPreviewError:{
		value:"String"
	},
	AtsJob:{
		name:"String",
		id:"String",
		stages:"AtsStage"
	},
	AtsStage:{
		name:"String",
		id:"String",
		index:"Int"
	},
	AssessmentConnection:{
		pageInfo:"PageInfo",
		edges:"AssessmentEdge"
	},
	AssessmentEdge:{
		node:"Assessment",
		cursor:"String"
	},
	PersonalityTestResult:{
		name:"String",
		scales:"PersonalityTestScale"
	},
	PersonalityTestScale:{
		name:"String",
		scoreRange:"ScoreRange",
		description:"String",
		comment:"String"
	},
	CandidateQuery:{
		solutionAttemptCandidate:"SolutionAttemptCandidate",
		solutionResults:"SolutionResults",
		assessment:"CandidateAssessment",
		solution:"CandidateSolution",
		candidateTestPreview:"TestPreviewResult",
		candidateSolutionPreview:"CandidateSolutionPreview"
	},
	SolutionAttemptCandidate:{
		firstName:"String",
		lastName:"String",
		email:"String",
		canAttempt:"Boolean"
	},
	SolutionResults:{
		scales:"CandidateScaleResult"
	},
	CandidateScaleResult:{
		name:"String",
		comment:"String"
	},
	CandidateAssessment:{
		skills:"CandidateSkill"
	},
	CandidateSkill:{
		name:"String",
		type:"SkillType"
	},
	CandidateSolution:{
		suggestedTime:"Int",
		testType:"TestType",
		showComments:"Boolean",
		companyName:"String",
		tests:"Test"
	},
	Test:{
		availableTime:"Int",
		id:"Int",
		questions:"TestQuestion",
		scorable:"Boolean",
		skillName:"String",
		skillType:"SkillType",
		softTimeLimit:"Int"
	},
	TestQuestion:{
		"...on SingleChoiceQuestion":"SingleChoiceQuestion",
		"...on MultipleChoiceQuestion":"MultipleChoiceQuestion",
		"...on ClosedNumberQuestion":"ClosedNumberQuestion",
		"...on OpenTextQuestion":"OpenTextQuestion",
		"...on LikertQuestion":"LikertQuestion",
		"...on SituationalQuestion":"SituationalQuestion"
	},
	SingleChoiceQuestion:{
		externalTool:"ExternalTool",
		possibleAnswers:"PossibleAnswer",
		uuid:"UUID",
		text:"String",
		image:"String",
		answerOptional:"Boolean",
		changingWindowAllowed:"Boolean"
	},
	PossibleAnswer:{
		uuid:"UUID",
		text:"String"
	},
	MultipleChoiceQuestion:{
		externalTool:"ExternalTool",
		possibleAnswers:"PossibleAnswer",
		uuid:"UUID",
		text:"String",
		image:"String",
		answerOptional:"Boolean",
		changingWindowAllowed:"Boolean",
		maxAnswers:"Int"
	},
	ClosedNumberQuestion:{
		externalTool:"ExternalTool",
		uuid:"UUID",
		text:"String",
		image:"String",
		answerOptional:"Boolean",
		changingWindowAllowed:"Boolean"
	},
	OpenTextQuestion:{
		externalTool:"ExternalTool",
		uuid:"UUID",
		text:"String",
		image:"String",
		answerOptional:"Boolean",
		changingWindowAllowed:"Boolean"
	},
	LikertQuestion:{
		uuid:"UUID",
		text:"String",
		image:"String",
		answerOptional:"Boolean",
		changingWindowAllowed:"Boolean"
	},
	SituationalQuestion:{
		possibleAnswers:"PossibleAnswer",
		uuid:"UUID",
		text:"String",
		image:"String",
		answerOptional:"Boolean",
		changingWindowAllowed:"Boolean",
		maxAnswers:"Int"
	},
	TestPreviewResult:{
		"...on TestPreview":"TestPreview",
		"...on TestPreviewUnavailable":"TestPreviewUnavailable"
	},
	TestPreview:{
		uuid:"UUID",
		title:"String",
		roleName:"String",
		availableTime:"Int",
		numberOfQuestions:"Int",
		numberOfOpenQuestions:"Int",
		description:"String",
		organizationName:"String",
		branding:"AccountBranding",
		requiresExternalTool:"Boolean",
		requiredExternalTools:"ExternalToolKind",
		locale:"Locale",
		talentPool:"Boolean",
		testType:"TestType",
		testLevel:"TestLevel",
		competencies:"Skill",
		createdAt:"DateTime"
	},
	TestPreviewUnavailable:{
		reason:"String"
	},
	CandidateSolutionPreview:{
		assessment:"TestPreview",
		solutionState:"SolutionState",
		candidate:"CandidatePreview",
		score:"Decimal",
		timeSpent:"Int",
		summary:"CandidateSummaryEntry"
	},
	CandidatePreview:{
		uuid:"UUID",
		phoneNumber:"String",
		firstName:"String",
		lastName:"String",
		email:"String"
	},
	CandidateSummaryEntry:{
		competency:"Competency",
		numberOfQuestions:"Int",
		score:"Decimal",
		comment:"String",
		label:"String"
	},
	Mutation:{
		auth:"Auth",
		heroTracking:"HeroTracking",
		candidate:"CandidateMutation",
		client:"ClientMutation"
	},
	Auth:{
		authorizationToken:"String"
	},
	HeroTracking:{
		result:"String"
	},
	CandidateMutation:{
		talentPoolOptIn:"TalentPoolOptIn",
		addCommentForQuestion:"AddCommentForQuestion",
		submitSolution:"SubmitSolution",
		reportAnomaly:"ReportAnomaly",
		startSolution:"StartSolution",
		submitLikertResponse:"SubmitLikertResponse",
		submitTest:"SubmitTest",
		registerCandidate:"RegisterCandidate",
		issue2faToken:"Issue2FAToken",
		verify2faToken:"Verify2FAToken",
		registerVerifiedCandidate:"RegisterVerifiedCandidate",
		startTest:"StartTest",
		seeQuestion:"SeeQuestion",
		submitResponse:"SubmitResponse",
		submitSituationalResponse:"SubmitSituationalResponse",
		skipQuestion:"SkipQuestion",
		giveFeedback:"CandidateGiveFeedback"
	},
	TalentPoolOptIn:{
		result:"Boolean"
	},
	AddCommentForQuestion:{
		result:"Boolean"
	},
	SubmitSolution:{
		result:"Boolean"
	},
	ReportAnomaly:{
		result:"Boolean"
	},
	StartSolution:{
		result:"SolutionAttempt"
	},
	SolutionAttempt:{
		suggestedTime:"Int",
		testType:"TestType",
		showComments:"Boolean",
		companyName:"String",
		tests:"Test",
		solutionId:"UUID"
	},
	SubmitLikertResponse:{
		result:"Boolean"
	},
	SubmitTest:{
		result:"String"
	},
	RegisterCandidate:{
		sessionToken:"String"
	},
	Issue2FAToken:{
		result:"String"
	},
	Verify2FAToken:{
		intermediateToken:"String"
	},
	RegisterVerifiedCandidate:{
		candidateSessionToken:"String"
	},
	StartTest:{
		result:"String"
	},
	SeeQuestion:{
		result:"String",
		responseId:"String"
	},
	SubmitResponse:{
		result:"String"
	},
	SubmitSituationalResponse:{
		result:"String"
	},
	SkipQuestion:{
		result:"String"
	},
	CandidateGiveFeedback:{
		result:"String"
	},
	ClientMutation:{
		startCheckoutSession:"StartCheckoutSession",
		cancelSubscription:"CancelSubscription",
		createAssessment:"CreateAssessment",
		signUp:"SignUp",
		submitOnboardingSurvey:"SubmitOnboardingSurvey",
		submitOnboardingAccountDetails:"SubmitOnboardingAccountDetails",
		visitOnboarding:"VisitOnboarding",
		sendEmail:"SendEmail",
		changeNotificationFrequency:"ChangeNotificationFrequency",
		solutionToken:"SolutionToken",
		changeAtsAssociationSettings:"ChangeAtsAssociationSettings",
		deleteAtsAssociation:"DeleteAtsAssociation",
		earlyAccessSignup:"EarlyAccessSignup",
		setPassword:"SetPassword",
		sendResetPassword:"SendResetPassword",
		archiveTest:"ArchiveTest",
		deleteTest:"DeleteTest",
		updateCandidatesStatus:"UpdateCandidatesStatus",
		updateBranding:"UpdateBranding",
		seeSolution:"ClientSeeSolution"
	},
	StartCheckoutSession:{
		result:"StartCheckoutSessionResult"
	},
	StartCheckoutSessionResult:{
		url:"String"
	},
	CancelSubscription:{
		result:"String"
	},
	CreateAssessment:{
		result:"Assessment"
	},
	SignUp:{
		result:"SignupResult"
	},
	SignupResult:{
		"...on SignupResultSuccess":"SignupResultSuccess",
		"...on SignupResultError":"SignupResultError"
	},
	SignupResultSuccess:{
		auth:"String",
		changePasswordToken:"String"
	},
	SignupResultError:{
		value:"String"
	},
	SubmitOnboardingSurvey:{
		result:"Boolean"
	},
	JSONString: `scalar.JSONString` as const,
	SubmitOnboardingAccountDetails:{
		result:"SubmitOnboardingAccountDetailsResult"
	},
	SubmitOnboardingAccountDetailsResult:{
		"...on SubmitOnboardingAccountDetailsSuccess":"SubmitOnboardingAccountDetailsSuccess",
		"...on SiubmitOnboardingAccountDetailsError":"SiubmitOnboardingAccountDetailsError"
	},
	SubmitOnboardingAccountDetailsSuccess:{
		result:"Boolean"
	},
	SiubmitOnboardingAccountDetailsError:{
		error:"String"
	},
	VisitOnboarding:{
		result:"Boolean"
	},
	SendEmail:{
		result:"Boolean"
	},
	ChangeNotificationFrequency:{
		result:"Boolean"
	},
	SolutionToken:{
		result:"SolutionTokenResult"
	},
	SolutionTokenResult:{
		"...on SolutionTokenResultSuccess":"SolutionTokenResultSuccess",
		"...on SolutionTokenResultError":"SolutionTokenResultError"
	},
	SolutionTokenResultSuccess:{
		token:"String"
	},
	SolutionTokenResultError:{
		error:"String"
	},
	ChangeAtsAssociationSettings:{
		result:"ChangeAtsAssociationSettingsUnion"
	},
	ChangeAtsAssociationSettingsUnion:{
		"...on ChangeAtsAssociationSettingsSuccess":"ChangeAtsAssociationSettingsSuccess",
		"...on ChangeAtsAssociationSettingsError":"ChangeAtsAssociationSettingsError"
	},
	ChangeAtsAssociationSettingsSuccess:{
		result:"Boolean"
	},
	ChangeAtsAssociationSettingsError:{
		result:"Boolean",
		error:"String"
	},
	DeleteAtsAssociation:{
		result:"DeleteAtsAssociationUnion"
	},
	DeleteAtsAssociationUnion:{
		"...on DeleteAtsAssociationError":"DeleteAtsAssociationError",
		"...on DeleteAtsAssociationSuccess":"DeleteAtsAssociationSuccess"
	},
	DeleteAtsAssociationError:{
		result:"Boolean",
		error:"String"
	},
	DeleteAtsAssociationSuccess:{
		result:"Boolean"
	},
	EarlyAccessSignup:{
		result:"EarlyAccessSignupResult"
	},
	EarlyAccessSignupResult:{
		status:"Boolean",
		errors:"Error"
	},
	Error:{
		name:"String",
		message:"String"
	},
	SetPassword:{
		authorizationToken:"String"
	},
	SendResetPassword:{
		Result:"String",
		result:"String"
	},
	ArchiveTest:{
		result:"String"
	},
	DeleteTest:{
		result:"String"
	},
	UpdateCandidatesStatus:{
		candidates:"SolutionCandidate"
	},
	UpdateBranding:{
		result:"String"
	},
	Upload: `scalar.Upload` as const,
	ClientSeeSolution:{
		result:"String"
	}
}

export const Ops = {
query: "Query" as const,
	subscription: "Subscription" as const,
	mutation: "Mutation" as const
}